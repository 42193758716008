import { coursesConstants } from '../constants'

export const loadCourses = () => {
  return {
    type: coursesConstants.COURSES_INIT
  }
}

export const updateCourses = (payload) => {
  return {
    type: coursesConstants.COURSES_UPDATE,
    payload
  }
}

export const removeCourses = (payload) => {
  return {
    type: coursesConstants.COURSES_REMOVE,
    payload
  }
}
