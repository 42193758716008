import React, { Fragment, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'

import css from './Nav.module.scss'
import { uiConstants } from '../../../../constants'
import Logo from '../../../../assets/logo_black.svg'
import Defendo from '../../../../assets/defendo.svg'

const Nav = () => {
  const [isOpened, setIsOpened] = useState(false)

  const toggleMenu = () => {
    setIsOpened(!isOpened)
  }

  const closeMenuHandler = () => {
    if (isOpened) {
      setIsOpened(false)
    }
  }

  return (
    <Fragment>
      <nav className={css.Nav}>
        <button
          className={`hamburger hamburger--arrowturn ${
            isOpened ? 'is-active' : ''
          }`}
          onClick={toggleMenu}
          aria-label='Menu'
        >
          <span className='hamburger-box'>
            <span className='hamburger-inner'></span>
          </span>
        </button>
        <Link to='/' className={css.Logo} onClick={closeMenuHandler}>
          <img src={Logo} alt='Defendo Krav Maga' />
        </Link>
        <NavLink
          to='/dove-siamo?locate=true'
          className={css.Locate}
          onClick={closeMenuHandler}
        >
          <i className='fa-solid fa-location-dot'></i>
        </NavLink>
      </nav>
      <div className={css.BlackBar}>
        <Link to='/'>
          <img src={Defendo} alt='Defendo Krav Maga' />
        </Link>
      </div>
      <div className={`${css.Menu} ${isOpened ? css.MenuOpen : ''}`}>
        <ul className={css.UlMenu}>
          {uiConstants.menu.map((m) => (
            <li key={m.link}>
              <NavLink
                onClick={toggleMenu}
                to={m.link}
                className={({ isActive }) =>
                  isActive ? css.NavActive : css.NavLink
                }
              >
                {m.label}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </Fragment>
  )
}

export default Nav
