import React from 'react'

import Logo from '../../../assets/logo_white.svg'
import Paragraph from '../Paragraph/Paragraph'
import css from './PageLoader.module.scss'

const PageLoader = ({ message }) => (
  <div className={css.LoaderBkg}>
    <div className={css.Loader}></div>
    <div className={css.Logo}>
      <img src={Logo} alt='Defendo' />
    </div>
    {message && (
      <div className={css.Message}>
        <Paragraph text={message} />
      </div>
    )}
  </div>
)

export default PageLoader
