import { coursesConstants } from '../constants'

const initialState = {
  list: null,
  init: false
}

export default function courses(state = initialState, action) {
  switch (action.type) {
    case coursesConstants.COURSES_UPDATE:
      return {
        ...state,
        init: true,
        list: (state.list === null
          ? [action.payload]
          : state.list.findIndex((x) => x.id === action.payload.id) === -1
          ? state.list.concat(action.payload)
          : state.list.map((item) => {
              return item.id === action.payload.id ? action.payload : item
            })
        ).sort((a, b) => a.order - b.order)
      }
    case coursesConstants.COURSES_REMOVE:
      return {
        ...state,
        list: state.list
          .filter((item) => {
            return item.id !== action.payload.id
          })
          .sort((a, b) => a.order - b.order)
      }
    default:
      return state
  }
}
