import css from './Footer.module.scss'

import Logo from '../../../../assets/patch.svg'
import Social from './Social/Social'

const Footer = () => {
  return (
    <footer className={css.Footer}>
      <img src={Logo} alt='Defendo Krav Maga' />
      <br />
      <br />
      <Social />
      <br />
      <br />
      &copy;Defendo Krav Maga {new Date().getFullYear()}. All Rights Reserved.
      <br />
      <br />
      NCAGE AL 702 - UNGM 240277
      <br />
      <br />
      <a href='mailto:info@defendokravmaga.it'>info@defendokravmaga.it</a>
      <br />
      Bergamo - Como - Lecco - Monza Brianza - Milano
      <br />
      <br />
      v. {document.head.querySelector('meta[name="build-version"]')['content']}
    </footer>
  )
}

export default Footer
