import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link, useLocation, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import emailjs from '@emailjs/browser'

import css from './Contacts.module.scss'

import PageLoader from '../../../UI/PageLoader/PageLoader'
import { emailConstants } from '../../../../constants'
import OrsoFrontale from '../../../../assets/orsofrontale.jpg'
import Box from '../../../UI/Box/Box'

const Contacts = ({ team, courses }) => {
  const { pathname } = useLocation()
  const params = useParams()
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid }
  } = useForm()

  const onSubmit = (data) => {
    const u = pathname.split('/')
    data.page = u[u.length - 1]
    data.recipient = emailConstants.defaultRecipient

    if (params.nickname) {
      const i = team.list.find((x) => x.link === params.nickname)
      data.recipient = i.email
    } else if (params.link) {
      const c = courses.list.find((x) => x.link === params.link)
      const i = team.list.find((x) => x.link === c.owner)
      data.recipient = i.email
    }

    setLoading(true)
    emailjs
      .send(
        emailConstants.emailjs.serviceId,
        emailConstants.emailjs.templateId,
        data,
        emailConstants.emailjs.publicKey
      )
      .then(() => {
        reset()
        setMessage(
          'Messaggio inviato con successo! Verrete ricontattati il più presto possibile.'
        )
      })
      .catch((error) => {
        console.log(error.text)
        setMessage("Errore nell'invio del messaggio, riprovare.")
      })
      .finally(() => {
        setLoading(false)
        setTimeout(() => {
          setMessage('')
        }, 5000)
      })
  }

  return (
    <div className={css.Container}>
      <div className={css.Inside}>
        <h2>Contattaci</h2>
        <ul className={css.UlContacts}>
          <li>
            <form onSubmit={handleSubmit(onSubmit)}>
              <label>
                Nome (*)
                <input
                  type='text'
                  placeholder='Nome'
                  {...register('name', { required: true })}
                />
              </label>
              <label>
                Cognome
                <input
                  type='text'
                  placeholder='Cognome'
                  {...register('surname', { required: false })}
                />
              </label>
              <label>
                Email (*)
                <input
                  type='email'
                  placeholder='Email'
                  {...register('email', { required: true })}
                />
              </label>
              <label>
                Telefono
                <input
                  placeholder='Telefono'
                  type='tel'
                  {...register('phone', { required: false })}
                />
              </label>
              <label>
                Messaggio (*)
                <textarea
                  placeholder='Messaggio'
                  {...register('message', { required: true })}
                ></textarea>
              </label>
              <div className={css.Privacy}>
                I campi contrassegnati con (*) sono obbligatori. Inviado la
                richiesta accetti la nostra{' '}
                <Link to='/privacy-policy'>Privacy Policy</Link>.
              </div>
              <button type='submit' className={css.Button} disabled={!isValid}>
                invia
              </button>
              <div className={css.Message}>{message}</div>
            </form>
          </li>
          <li>
            <Box
              title='info@defendokravmaga.it'
              description={
                'Scrivici una mail, verrai ricontattato al più presto.'
              }
              to='mailto:info@defendokravmaga.it'
              image={OrsoFrontale}
            />
          </li>
        </ul>
        {loading && <PageLoader />}
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, {})(Contacts)
