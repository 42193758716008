import { uiConstants } from '../../../../../constants/ui.constants'

import css from './Social.module.scss'

const Social = () => (
  <div className={css.Container}>
    <ul className={css.UlSocial}>
      {uiConstants.social.map((social, index) => (
        <li key={index}>
          <a href={social.link} target='_blank' rel='noreferrer'>
            <i className={social.icon} />
          </a>
        </li>
      ))}
    </ul>
  </div>
)

export default Social
