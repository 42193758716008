import { takeEvery } from 'redux-saga/effects'
import { coursesConstants, teamConstants } from '../constants'

import { loadCoursesSaga } from './courses.sagas'
import { loadTeamSaga } from './team.sagas'

export function* watchCourses() {
  yield takeEvery(coursesConstants.COURSES_INIT, loadCoursesSaga)
}

export function* watchTeam() {
  yield takeEvery(teamConstants.TEAM_INIT, loadTeamSaga)
}
