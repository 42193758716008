import { teamConstants } from '../constants'

export const loadTeam = () => {
  return {
    type: teamConstants.TEAM_INIT
  }
}

export const updateTeam = (payload) => {
  return {
    type: teamConstants.TEAM_UPDATE,
    payload
  }
}

export const removeTeam = (payload) => {
  return {
    type: teamConstants.TEAM_REMOVE,
    payload
  }
}
