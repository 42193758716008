import { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'

import { loadTeam, loadCourses } from '../../../../redux/actions'

const LoadCollections = ({ team, courses }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (!team.init) {
      dispatch(loadTeam())
    }
    if (!courses.init) {
      dispatch(loadCourses())
    }
  }, [courses.init, dispatch, team.init])

  return null
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, {})(LoadCollections)
