import { combineReducers } from 'redux'

import team from './team.reducer'
import courses from './courses.reducer'

const rootReducer = combineReducers({
  courses,
  team
})

export default rootReducer
