import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from './redux/store'

import PageLoader from './components/UI/PageLoader/PageLoader'
import Layout from './components/Containers/Layout/Layout'

/* moment */
import moment from 'moment-timezone'
import 'moment/locale/it'
moment.tz.setDefault('Europe/London')

const HomeLazy = lazy(() => import('./components/Pages/Home/Home'))
const ChiSiamoLazy = lazy(() => import('./components/Pages/ChiSiamo/ChiSiamo'))
const DifesaPersonaleLazy = lazy(() =>
  import('./components/Pages/DifesaPersonale/DifesaPersonale')
)
const DifesaFemminileLazy = lazy(() =>
  import('./components/Pages/DifesaFemminile/DifesaFemminile')
)
const LezioniPrivateLazy = lazy(() =>
  import('./components/Pages/LezioniPrivate/LezioniPrivate')
)
const TeamLazy = lazy(() => import('./components/Pages/Team/Team'))
const DoveSiamoLazy = lazy(() =>
  import('./components/Pages/DoveSiamo/DoveSiamo')
)
const PrivacyPolicyLazy = lazy(() =>
  import('./components/Pages/PrivacyPolicy/PrivacyPolicy')
)
const IscrizioniLazy = lazy(() =>
  import('./components/Pages/Iscrizioni/Iscrizioni')
)
const InstructorLazy = lazy(() =>
  import('./components/Pages/Instructor/Instructor')
)
const CourseLazy = lazy(() => import('./components/Pages/Course/Course'))
const PartnersLazy = lazy(() => import('./components/Pages/Partners/Partners'))
const PressKitLazy = lazy(() => import('./components/Pages/PressKit/PressKit'))

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<PageLoader />}>
          <Routes>
            <Route element={<Layout />}>
              <Route index element={<HomeLazy />} />
              <Route path='/chi-siamo' element={<ChiSiamoLazy />} />
              <Route
                path='/difesa-personale'
                element={<DifesaPersonaleLazy />}
              />
              <Route
                path='/difesa-femminile'
                element={<DifesaFemminileLazy />}
              />
              <Route path='/lezioni-private' element={<LezioniPrivateLazy />} />
              <Route path='team' element={<TeamLazy />} />
              <Route path='/team/:nickname' element={<InstructorLazy />} />
              <Route path='/dove-siamo' element={<DoveSiamoLazy />} />
              <Route path='/dove-siamo/:link' element={<CourseLazy />} />
              <Route path='/privacy-policy' element={<PrivacyPolicyLazy />} />
              <Route path='/iscrizioni' element={<IscrizioniLazy />} />
              <Route path='/partners' element={<PartnersLazy />} />
              <Route path='/press-kit' element={<PressKitLazy />} />
              <Route path='*' element={<HomeLazy />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </Provider>
  )
}

export default App
