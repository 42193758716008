import css from './Payoff.module.scss'

const Payoff = () => (
  <div className={css.Payoff}>
    <div className={css.Inside}>
      Non esiste l'arte marziale migliore per eccellenza, ma di una cosa siamo
      sicuri: l'ESPERIENZA.
      <br />
      <br />
      La professionalità e la competenza acquisita in tanti anni, fanno della
      DEFENDO, un gruppo di esperti nel settore della difesa personale.
    </div>
  </div>
)

export default Payoff
