import DefendoPatch from '../assets/patch.jpg'
import DifesaFemminile from '../assets/difesafemminile.jpg'
import Scale from '../assets/scale.jpg'
import OrsoDietro from '../assets/orsodietro.jpg'
import Corridoio from '../assets/corridoio.jpg'
import Coltello from '../assets/coltello.jpg'

export const uiConstants = {
  social: [
    {
      icon: 'fa-brands fa-instagram',
      link: 'https://www.instagram.com/defendo.kravmaga'
    },
    {
      icon: 'fa-regular fa-envelope',
      link: 'mailto:info@defendokravmaga.it'
    },
    {
      icon: 'fa-brands fa-facebook',
      link: 'https://www.facebook.com/defendokravmaga'
    },
    {
      icon: 'fa-brands fa-twitter',
      link: 'https://www.twitter.com/defendokravmaga'
    },
    {
      icon: 'fa-brands fa-facebook-messenger',
      link: 'https://m.me/defendokravmaga'
    }
  ],
  menu: [
    {
      label: 'Home',
      link: '/'
    },
    {
      label: 'Chi siamo',
      link: '/chi-siamo'
    },
    {
      label: 'Difesa personale',
      link: '/difesa-personale'
    },
    {
      label: 'Difesa femminile',
      link: '/difesa-femminile'
    },
    {
      label: 'Lezioni private',
      link: '/lezioni-private'
    },
    {
      label: 'Iscrizioni',
      link: '/iscrizioni'
    },
    {
      label: 'Team',
      link: '/team'
    },
    {
      label: 'Dove siamo - corsi',
      link: '/dove-siamo'
    },
    {
      label: 'Partners',
      link: '/partners'
    },
    {
      label: 'Press Kit',
      link: '/press-kit'
    }
  ],
  sliders: [
    {
      id: 'dp',
      image: DefendoPatch,
      title: "Iscrizioni aperte tutto l'anno",
      description: 'Cerca il corso più vicino a te',
      label: 'Scopri dove siamo',
      to: '/dove-siamo'
    },
    {
      id: 'df',
      image: DifesaFemminile,
      title: 'Difesa femminile',
      description: 'Impara a difendere te stessa e chi ami',
      label: 'Scopri di più',
      to: '/difesa-femminile'
    },
    {
      id: 'dkm',
      image: Corridoio,
      title: 'Chi siamo',
      description:
        'Defendo Krav Maga nasce nel 2012, dalla nostra esperienza di istruttori FederKravMaga Italia e World Krav Maga Federation.',
      label: 'Scopri di più',
      to: '/chi-siamo'
    }
  ],
  boxes: [
    {
      image: Coltello,
      title: 'Prima lezione gratis',
      description:
        "La prima lezione è di prova, gratuita e senza impegno. Presentati all'inizio della lezione per parlare con l'istruttore e per usufruire della tua prova gratuita.",
      to: '/dove-siamo'
    },
    {
      image: OrsoDietro,
      title: 'Iscrizioni',
      description:
        'Scopri come iscriverti o leggi le FAQ per avere maggiori informazioni.',
      to: '/iscrizioni'
    },
    {
      image: Scale,
      title: 'difesa femminile / vademecum e numeri utili',
      description:
        'Difesa femminile, se vuoi parlarne con qualcuno, qui trovi i numeri utili che potrebbero fare al caso tuo e un decalogo su come comportarsi.',
      to: '/difesa-femminile'
    }
  ],
  contacts: [
    {
      name: 'name',
      label: 'Nome Cognome',
      required: true,
      type: 'text',
      placeholder: 'Nome Cognome'
    },
    {
      name: 'email',
      label: 'Email',
      required: true,
      type: 'email',
      placeholder: 'Email'
    },
    {
      name: 'phone',
      label: 'Telefono',
      required: false,
      type: 'tel',
      placeholder: 'Telefono'
    },
    {
      name: 'message',
      label: 'Messaggio',
      required: true,
      type: 'textarea',
      placeholder: 'Messaggio'
    }
  ],
  partners: [
    {
      title: 'Home Defense',
      description: 'Corsi di tiro operativo e difesa abitativa.',
      link: 'https://homedefense.it'
    },
    {
      title: 'Kill house',
      description: 'Poligono al chiuso per formazione con armi T4E Umarex.',
      link: 'https://killhouse.it'
    },
    {
      title: 'Exercui',
      description: 'Corsi di autodifesa, tiro operativo, sopravvivenza.',
      link: 'https://exercui.it'
    },
    {
      title: 'Girls Defense',
      description:
        'GirlsDefense è un progetto dedicato alla organizzazione e sponsorizzazione di corsi di difesa personale, uso e maneggio delle armi in sicurezza per tutte le donne di ogni età e condizione fisica.',
      link: 'https://girlsdefense.it'
    },
    {
      title: 'Tacti.Club',
      description:
        'Un club esclusivo, dove poter accumulare punti per poter usufruire di sconti su corsi e prodotti.',
      link: 'https://tacti.club'
    }
  ],
  pressKit: [
    'defendo_black.svg',
    'defendo_white.svg',
    'defendo.jpg',
    'defendo.png',
    'logo_black.jpg',
    'logo_black.png',
    'logo_white.png'
  ]
}
