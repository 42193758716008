import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyDze3acRGJstoqlnLspSEucNh_83JwIWwc',
  authDomain: 'defendo-972a3.firebaseapp.com',
  projectId: 'defendo-972a3',
  storageBucket: 'defendo-972a3.appspot.com',
  messagingSenderId: '68930410276',
  appId: '1:68930410276:web:682e9a058791d51921c5b1',
  measurementId: 'G-0YSN6M2J00'
}

export const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const db = getFirestore(app)
export const storage = getStorage(app)
