import { put, take } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { coursesRef } from '../../database'
import { onSnapshot } from 'firebase/firestore'

import { updateCourses, removeCourses } from '../actions'

export let coursesSnap

export function* loadCoursesSaga() {
  try {
    const listener = eventChannel((emit) => {
      coursesSnap = onSnapshot(coursesRef, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          emit(change)
        })
      })
      return () => coursesSnap()
    })
    while (true) {
      const change = yield take(listener)
      const data = change.doc.data()
      const id = change.doc.id
      if (id === '_._') continue
      if (change.type === 'added') {
        yield put(updateCourses({ id, ...data }))
      }
      if (change.type === 'modified') {
        yield put(updateCourses({ id, ...data }))
      }
      if (change.type === 'removed') {
        yield put(removeCourses({ id, ...data }))
      }
    }
  } catch (error) {
    console.log(error)
  }
}
