import css from './Paragraph.module.scss'

const Paragraph = ({ text, size }) => (
  <div className={css.Paragraph}>
    {text.split('\n').map((x, key) => (
      <span key={key} style={{ minHeight: size }}>
        {x}
      </span>
    ))}
  </div>
)

export default Paragraph
