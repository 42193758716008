import React, { useEffect, useState, Fragment } from 'react'

import css from './ScrollTop.module.scss'

const ScrollTop = () => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleScroll = () => {
    const windowHeight =
      'innerHeight' in window
        ? window.innerHeight
        : document.documentElement.offsetHeight
    if (windowHeight < window.pageYOffset) {
      setShow(true)
    } else {
      setShow(false)
    }
  }

  const goToTop = () => {
    window.scrollTo(0, 0)
  }

  return (
    <Fragment>
      {show && (
        <button
          className={css.ScrollTop}
          onClick={goToTop}
          aria-label='Scroll Top'
        >
          <i className='fas fa-chevron-up'></i>
        </button>
      )}
    </Fragment>
  )
}

export default ScrollTop
