import React from 'react'
import { Link } from 'react-router-dom'

import css from './Box.module.scss'

const Box = ({ title, description, to, image }) => (
  <Link to={to} className={css.Box}>
    {typeof image === 'string' ? <img src={image} alt={title} /> : image}
    <ul className={css.Content}>
      <li>{title}</li>
      <li>{description}</li>
    </ul>
    <div className={css.Icon}>
      <i className='fa-solid fa-arrow-right'></i>
    </div>
  </Link>
)

export default Box
